import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import "core-js/modules/es6.map.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/web.dom.iterable.js";
import "core-js/modules/es6.array.map.js";
import { useQuery } from "@vue/apollo-composable";
import moment from "moment";
import { useBudgetsBalanceQuery } from "~/composables/queries/useBudgetsBalanceQuery";
import { usePropertyBudgetRecordsQuery } from "~/composables/queries/usePropertyBudgetRecordsQuery";
import { PropertyBudgetsDocument } from "~/graphql/generated/graphql";
import { hasValue } from "~/helpers/common-helpers";
import { getAccountAggregationKey } from "~/helpers/portfolio/portfolio-balance-helpers";
import { getBudgetCategoryMap, getSetValue } from "~/helpers/portfolio/portfolio-category-helpers";
export var useBalanceAndResultsBudgets = function useBalanceAndResultsBudgets(preferences, preferencesLoading, propertyId) {
  var _budgetRecordsMap = ref(new Map());
  var _budgets = ref([]);
  var budgetRecordsMap = computed(function () {
    return _budgetRecordsMap.value;
  });
  var budgets = computed(function () {
    return _budgets.value;
  });
  var budgetsLoading = ref(false);
  var loading = computed(function () {
    return budgetsLoading.value || !!preferencesLoading.value;
  });
  if (propertyId) {
    var selectedPropertyBudgets = computed(function () {
      var _preferences$value$pr, _preferences$value;
      return (_preferences$value$pr = (_preferences$value = preferences.value) === null || _preferences$value === void 0 ? void 0 : _preferences$value.propertyBudgets[propertyId]) !== null && _preferences$value$pr !== void 0 ? _preferences$value$pr : [];
    });
    var budgetRecordsQuery = usePropertyBudgetRecordsQuery(computed(function () {
      var _selectedPropertyBudg, _selectedPropertyBudg2;
      return (_selectedPropertyBudg = (_selectedPropertyBudg2 = selectedPropertyBudgets.value) === null || _selectedPropertyBudg2 === void 0 ? void 0 : _selectedPropertyBudg2.map(function (budget) {
        return budget.id;
      })) !== null && _selectedPropertyBudg !== void 0 ? _selectedPropertyBudg : [];
    }), computed(function () {
      var _preferences$value2;
      return !!((_preferences$value2 = preferences.value) !== null && _preferences$value2 !== void 0 && _preferences$value2.showBudgets);
    }));
    var budgetsBalanceQuery = useBudgetsBalanceQuery(propertyId, selectedPropertyBudgets);
    var budgetsQuery = useQuery(PropertyBudgetsDocument, function () {
      return {
        assetManagementPropertyId: propertyId
      };
    });
    var getBudgetRecordsMap = function getBudgetRecordsMap(preferences) {
      var _budgetsBalanceQuery$;
      var periodType = preferences.periodType;
      var budgetRecords = budgetRecordsQuery.result.value;
      var accountCells = (_budgetsBalanceQuery$ = budgetsBalanceQuery.result.value) !== null && _budgetsBalanceQuery$ !== void 0 ? _budgetsBalanceQuery$ : [];
      var accountMap = new Map();
      for (var i = 0; i < accountCells.length; i++) {
        var account = accountCells[i];
        var accountKey = getAccountAggregationKey(account);
        if (!hasValue(accountKey)) continue;
        var period = account.period,
          totalCostWithVat = account.totalCostWithVat,
          categoryId = account.categoryId;
        var periodMs = moment.utc(period).valueOf();
        var periodGroup = getSetValue(periodMs, accountMap, function () {
          return new Map();
        });
        var periodCategoryGroup = getSetValue(categoryId, periodGroup, function () {
          return new Map();
        });
        periodCategoryGroup.set(accountKey, totalCostWithVat);
      }
      return getBudgetCategoryMap(budgetRecords, periodType, accountMap);
    };
    var _budgetsLoading = computed(function () {
      return budgetRecordsQuery.loading.value || budgetsQuery.loading.value || budgetsBalanceQuery.loading.value;
    });
    watch([_budgetsLoading, preferences], function (_ref) {
      var _budgetsQuery$result$, _budgetsQuery$result$2, _budgetsQuery$result$3, _budgetsQuery$result$4;
      var _ref2 = _slicedToArray(_ref, 2),
        loading = _ref2[0],
        preferences = _ref2[1];
      budgetsLoading.value = loading;
      if (loading || !preferences) return;
      _budgetRecordsMap.value = getBudgetRecordsMap(preferences);
      _budgets.value = (_budgetsQuery$result$ = (_budgetsQuery$result$2 = budgetsQuery.result.value) === null || _budgetsQuery$result$2 === void 0 ? void 0 : (_budgetsQuery$result$3 = _budgetsQuery$result$2.assetManagementProperty) === null || _budgetsQuery$result$3 === void 0 ? void 0 : (_budgetsQuery$result$4 = _budgetsQuery$result$3.assetManagementBudgets) === null || _budgetsQuery$result$4 === void 0 ? void 0 : _budgetsQuery$result$4.items) !== null && _budgetsQuery$result$ !== void 0 ? _budgetsQuery$result$ : [];
    }, {
      immediate: true
    });
  }
  return {
    budgetRecordsMap: budgetRecordsMap,
    budgets: budgets,
    loading: loading
  };
};
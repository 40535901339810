import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import "core-js/modules/es6.array.find.js";
import moment from "moment";
import { useBalanceAndResultsBudgets } from "./useBalanceAndResultsBudgets";
import { fitDate, getRowPeriodHeaders, getRowValueHeaders } from "~/helpers/portfolio/portfolio-balance-helpers";
import { getCategories as getCategoriesBase } from "~/helpers/portfolio/portfolio-category-helpers";
import { useRawBalanceBalanceQuery } from "~/composables/queries/useRawBalanceBalanceQuery";
import { useRawBalanceResultsQuery } from "~/composables/queries/useRawBalanceResultsQuery";
import { getDateRangeConfigColumns } from "~/helpers/portfolio/portfolio-opex-helpers";
export var useBalanceAndResultsData = function useBalanceAndResultsData(preferences, preferencesLoading, _ref) {
  var propertyId = _ref.propertyId,
    portfolioId = _ref.portfolioId;
  var balanceQuery = useRawBalanceBalanceQuery({
    portfolioId: portfolioId,
    propertyId: propertyId
  });
  var resultsQuery = useRawBalanceResultsQuery({
    portfolioId: portfolioId,
    propertyId: propertyId
  });
  var results = computed(function () {
    return resultsQuery.result.value;
  });
  var balance = computed(function () {
    return balanceQuery.result.value;
  });
  var _resultsCategories = ref([]);
  var _balanceCategories = ref([]);
  var _columnPeriodHeaders = ref([]);
  var _columnValueHeaders = ref([]);
  var _currency = ref();
  var _useBalanceAndResults = useBalanceAndResultsBudgets(preferences, preferencesLoading, propertyId),
    budgetRecordsMap = _useBalanceAndResults.budgetRecordsMap,
    budgets = _useBalanceAndResults.budgets,
    budgetsLoading = _useBalanceAndResults.loading;
  var loading = computed(function () {
    return budgetsLoading.value || resultsQuery.loading.value || balanceQuery.loading.value || preferencesLoading.value;
  });
  var _columns = ref([]);
  watch([loading, preferences], function (_ref2) {
    var _ref4, _balance$value$find;
    var _ref3 = _slicedToArray(_ref2, 2),
      loading = _ref3[0],
      preferences = _ref3[1];
    if (loading || !preferences) return;
    _columns.value = getDateRangeConfigColumns(preferences).reverse();
    var getCategories = function getCategories(data, type) {
      var periodType = preferences.periodType;
      var map = budgetRecordsMap.value;
      var showForecasts = !!(preferences.showBudgets && preferences.showBudgetForecasts);
      return getCategoriesBase(data, _columns.value, map, periodType, showForecasts, type);
    };
    var currentPeriod = fitDate(moment.utc(), preferences.periodType);
    _resultsCategories.value = getCategories(results.value, "results");
    _balanceCategories.value = getCategories(balance.value, "balance");
    _columnValueHeaders.value = getRowValueHeaders(_columns.value, currentPeriod, budgetRecordsMap.value, preferences);
    _columnPeriodHeaders.value = getRowPeriodHeaders(_columns.value, currentPeriod, budgetRecordsMap.value, preferences);
    _currency.value = (_ref4 = (_balance$value$find = balance.value.find(function (c) {
      return !c.isBlueprint;
    })) !== null && _balance$value$find !== void 0 ? _balance$value$find : results.value.find(function (i) {
      return !i.isBlueprint;
    })) === null || _ref4 === void 0 ? void 0 : _ref4.currency;
  }, {
    immediate: true
  });
  return {
    columns: computed(function () {
      return _columns.value;
    }),
    currency: computed(function () {
      return _currency.value;
    }),
    resultsCategories: computed(function () {
      return _resultsCategories.value;
    }),
    balanceCategories: computed(function () {
      return _balanceCategories.value;
    }),
    columnValueHeaders: computed(function () {
      return _columnValueHeaders.value;
    }),
    columnPeriodHeaders: computed(function () {
      return _columnPeriodHeaders.value;
    }),
    budgetRecordsMap: budgetRecordsMap,
    budgets: budgets,
    loading: loading
  };
};
import { render, staticRenderFns } from "./TableCellBalanceCategoryName.vue?vue&type=template&id=5d88d410"
import script from "./TableCellBalanceCategoryName.vue?vue&type=script&setup=true&lang=ts"
export * from "./TableCellBalanceCategoryName.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Icon: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/Icon/Icon.vue').default,CompositionSpacer: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/Composition/CompositionSpacer.vue').default,Copy: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/Copy/Copy.vue').default,LoaderCopy: require('/app/components/Loader/LoaderCopy.vue').default})

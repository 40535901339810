// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".grid-row_bIVXx{align-items:center;display:grid;grid-auto-flow:column;grid-column:1/-1;grid-template-columns:subgrid}.fixed_jZknt{background-color:var(--color-white);box-shadow:1px 1px 1px var(--color-black-100);position:sticky;top:0;z-index:1}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid-row": "grid-row_bIVXx",
	"fixed": "fixed_jZknt"
};
module.exports = ___CSS_LOADER_EXPORT___;

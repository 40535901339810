// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_s4kJ3{min-height:43rem}.table_4c\\+Tw{min-width:40rem}.table_4c\\+Tw.loading_N2unT{height:auto}.loaderContainer_JMWRa{align-items:center;display:flex;flex:1;justify-content:center}.loader_AXBnn{height:auto;width:auto}.loader_AXBnn,.loader_AXBnn *{position:static}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_s4kJ3",
	"table": "table_4c+Tw",
	"loading": "loading_N2unT",
	"loaderContainer": "loaderContainer_JMWRa",
	"loader": "loader_AXBnn"
};
module.exports = ___CSS_LOADER_EXPORT___;

import { render, staticRenderFns } from "./AccountSpecifications.vue?vue&type=template&id=5b5276e3"
import script from "./AccountSpecifications.vue?vue&type=script&lang=ts&setup=true"
export * from "./AccountSpecifications.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./AccountSpecifications.vue?vue&type=style&index=0&id=5b5276e3&prod&module=true&lang=css"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports
import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import { useQuery } from "@vue/apollo-composable";
import { BalancePortfolioDocument, BalancePropertyDocument } from "~/graphql/generated/graphql";
export var useRawBalanceBalanceQuery = function useRawBalanceBalanceQuery(_ref) {
  var propertyId = _ref.propertyId,
    portfolioId = _ref.portfolioId;
  var preferences = usePreferencesNamespace("preferencesNamespacePortfolioPropertyBalance");
  var dateRange = useBalanceSelectedDateRange();
  var variables = function variables() {
    var _dateRange$result$val, _dateRange$result$val2, _preferences$result$v;
    return {
      input: {
        from: (_dateRange$result$val = dateRange.result.value) === null || _dateRange$result$val === void 0 ? void 0 : _dateRange$result$val.from,
        to: (_dateRange$result$val2 = dateRange.result.value) === null || _dateRange$result$val2 === void 0 ? void 0 : _dateRange$result$val2.to,
        periodType: (_preferences$result$v = preferences.result.value) === null || _preferences$result$v === void 0 ? void 0 : _preferences$result$v.periodType,
        propertyId: propertyId,
        portfolioId: portfolioId
      }
    };
  };
  var propertyQuery = useQuery(BalancePropertyDocument, variables, function () {
    return {
      enabled: !!preferences.result.value && !!dateRange.result.value && !!propertyId
    };
  });
  var portfolioQuery = useQuery(BalancePortfolioDocument, variables, function () {
    return {
      enabled: !!preferences.result.value && !!dateRange.result.value && !!portfolioId
    };
  });
  var query = computed(function () {
    return propertyId ? propertyQuery : portfolioId ? portfolioQuery : null;
  });
  var result = computed(function () {
    var _query$value$result$v, _query$value, _query$value$result$v2;
    return _toConsumableArray((_query$value$result$v = (_query$value = query.value) === null || _query$value === void 0 ? void 0 : (_query$value$result$v2 = _query$value.result.value) === null || _query$value$result$v2 === void 0 ? void 0 : _query$value$result$v2.categoryCells) !== null && _query$value$result$v !== void 0 ? _query$value$result$v : []).sort(function (a, b) {
      return a.order - b.order;
    });
  });
  var loading = computed(function () {
    var _query$value2;
    return ((_query$value2 = query.value) === null || _query$value2 === void 0 ? void 0 : _query$value2.loading.value) || preferences.loading.value || dateRange.loading.value;
  });
  return {
    result: result,
    loading: loading
  };
};
// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".even_M8SJN{background-color:var(--color-supportive-3-50)!important}.headerCell_e3DtZ{padding:0 .3rem}.tableHead_3U\\+FB{background-color:var(--color-white);position:sticky;top:0;z-index:3}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"even": "even_M8SJN",
	"headerCell": "headerCell_e3DtZ",
	"tableHead": "tableHead_3U+FB"
};
module.exports = ___CSS_LOADER_EXPORT___;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".grid-cell_dJ8pX{display:flex;height:100%;width:100%}.fixed_FpfZC{background-color:var(--color-white);position:sticky;z-index:1}.fixedLeft_g2G9X{left:0!important}.fixedLeft_g2G9X,.fixedTop_KNbhV{box-shadow:1px 1px 1px var(--color-black-100)}.fixedTop_KNbhV{top:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid-cell": "grid-cell_dJ8pX",
	"fixed": "fixed_FpfZC",
	"fixedLeft": "fixedLeft_g2G9X",
	"fixedTop": "fixedTop_KNbhV"
};
module.exports = ___CSS_LOADER_EXPORT___;

import { render, staticRenderFns } from "./TableCellBalanceAccountName.vue?vue&type=template&id=a3cb9dbc"
import script from "./TableCellBalanceAccountName.vue?vue&type=script&setup=true&lang=ts"
export * from "./TableCellBalanceAccountName.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CompositionSpacer: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/Composition/CompositionSpacer.vue').default,Copy: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/Copy/Copy.vue').default})
